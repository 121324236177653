<template>
  <div>
    <asideBar />
    <div class="main-content flex-fill" :style="{ 'margin-left': $store.state.isMobile ? '0px' : $store.state.collapse ? '200px' : '60px' }">
      <div class="placeholder"></div>
      <div class="header-big no-bg mt-0 mb-2 mb-md-3 pb-0 pt-2 pt-md-5">
        <div class="s-search m-md-0 px-md-5">
          <div id="search" class="s-search mx-auto">
            <div class="searchDiv3">
              <el-input v-model="searchValue" placeholder="请输入关键字搜索" clearable></el-input>
              <div class="searchSlot" @click="searchClick">
                <i class="el-icon-search"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container customize-width px-md-5 mt-4 my-mt-5">
        <main class="content">
          <div class="content-wrap">
            <div class="content-layout">
              <!-- <div class="pb-3">
                <a class="btn btn-search mr-2 text-gray current" title="有关“图库”的综合">综合</a
                ><a class="btn btn-search mr-2 text-gray" title="有关“图库”的网站">网站</a
                ><a class="btn btn-search mr-2 text-gray" title="有关“图库”的文章">文章</a><a class="btn btn-search mr-2 text-gray">图片</a>
              </div> -->
              <div>
                <div class="block-header my-3 my-md-4">
                  <!-- <h4 class="block-title"><i class="io io-faxian" id="l-0"></i>网站&nbsp;4</h4>
                  <div class="flex-fill"></div> -->
                  <!-- <a href="?page=1&q=%E5%9B%BE%E5%BA%93&post_type=sites">更多<i class="iconfont icon-arrow-r-m"></i></a> -->
                </div>
                <div class="row">
                  <div class="url-card" v-for="item of list">
                    <div class="url-body">
                      <a class="url-content">
                        <div class="url-info">
                          <div class="overflowClip_1 mb-1 mb-md-2">
                            <strong>{{ item.title }}</strong>
                          </div>
                          <p class="overflowClip_2">
                            {{ item.content }}
                          </p>
                        </div>
                      </a>
                      <div class="url-link"></div>
                      <a class="url-details" target="_blank" rel="nofollow"><i class="iconfont icon-goto"></i></a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 列表为空展示 -->
              <div class="row" v-if="list && list.length == 0">
                <div class="col-lg-12">
                  <div class="nothing py-4 py-md-5 px-5">
                    <img src="../../assets/empty.svg" style="max-width: 360px; width: 100%;" class="mb-4 mt-2" alt="empty" />
                    <p>呐，内容都给你掏空了</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <!-- 弹框 -->
    <asideDrawer />
  </div>
</template>
<script>
import asideBar from '@/views/layout/components/aside'
import asideDrawer from '@/views/layout/components/asideDrawer'
import { getNavBySearch } from '@/api/http'
export default {
  components: {
    asideBar,
    asideDrawer
  },
  data() {
    return {
      searchValue: '',
      list: []
    }
  },
  // watch: {
  //   $route: {
  //     handler(val) {
  //       console.log('------val------', val.query.searchValue)
  //     },
  //     immediate: true
  //   }
  // },
  created() {
    this.searchValue = this.$route.query.searchValue
    this.getList()
  },
  destroyed() {},
  methods: {
    async getList() {
      var query = {
        q: this.searchValue
      }
      const data = await getNavBySearch(query)
      console.log(data.data.data.rows)
      this.list = data.data.data.rows
    },
    async searchClick() {
      if (this.searchValue) {
        var query = {
          q: this.searchValue
        }
        const data = await getNavBySearch(query)
        console.log(data.data.data.rows)
        this.list = data.data.data.rows
      } else {
        this.list = []
      }
    }
  }
}
</script>
<style scoped>
@import url('../css/bootstrap.css');
@import url('../css/style.css');

.category-swiper {
  white-space: nowrap;
  /* 确保内容在同一行显示 */
  overflow: hidden;
  /* 隐藏滚动条 */
  position: relative;
}

.category-swiper::before {
  content: '';
  display: block;
  height: 1px;
  /* 伪元素的高度可以是任意小值 */
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  /* 确保覆盖整个宽度 */
}

.swiper-wrapper {
  overflow-x: auto;
  /* 启用水平滚动 */
  padding-bottom: 17px;
  /* 额外的空间来防止内容被覆盖 */
}

/* 搜索部分 */
.searchDiv3 {
  border: Red 1px solid;
  display: flex;
  border: #2254f4 2px solid;
  border-radius: 10px;
  overflow: hidden;
  height: 43px;
}

.searchDiv3 .el-input__inner {
  width: calc(100% - 104px);
}

.searchDiv3 input {
  border: transparent 1px solid !important;
  font-size: 1.2rem;
}

.searchDiv3 .el-input__inner::-webkit-input-placeholder {
  color: #888;
  font-size: 1.2rem;
}

.searchDiv3 .searchSlot {
  width: 104px;
  cursor: pointer;
  display: block;
  font-size: 1.2rem;
  background: #2254f4;
  color: #fff;
  text-align: center;
  line-height: 43px;
}
</style>
